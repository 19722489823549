import * as React from 'react';
import { useState } from 'react';
import { Button } from 'primereact/button';
import './message-coupon.scss';
import { InputText } from 'primereact/inputtext';
import { STATUS_COUPON, navigateUrl, URL } from '../../utils/Utils';
import { decryptWithAES, generateUrl } from '../../utils/funtionCommon';

const MessageCouponComponent = props => {
  const { t, getCouponDetail, couponDetail, isLoading, setIsLoading, languageDefault } = props;
  const { detail } = couponDetail;
  const { isRequired2Fa, coupon_status, description2FA, encryptRecord2FA } = detail;

  const [code, setCode] = useState('');
  const [isCode, setIsCode] = useState(false);

  const submitCoupon = () => {
    if (code) {
      setIsCode(false);
      setIsLoading(true);
      setCode('');
      if (isRequired2Fa) {
        const convertCode = generateUrl(code);
        const dataDeScript = decryptWithAES(encryptRecord2FA, convertCode);
        if (dataDeScript) {
          const data = JSON.parse(dataDeScript);
          navigateUrl(`/${URL.REDEEM}?${URL.CODE}=${data.partner?.code || 'WID'}-${data.code}`);
          return;
        } else {
          setIsLoading(false);
          setIsCode(true);
        }
      } else {
        getCouponDetail(code);
      }
    } else {
      setIsCode(true);
    }
  };

  const onHandleCouponCode = e => {
    setCode(e.target.value);
  };

  return (
    <div className="coupon-message">
      <div className="coupon-message-container" title="Wilderness International"></div>
      <div className="coupon-form">
        <div className="coupon-form-container">
          <div className="form-container">
            <div className="row">
              <div className="col-md-12">
                <div className="icon-info">
                  <div className="icon-form">
                    <div className="info-container">
                      <i className="fa-solid fa-circle-info"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-form">
                {isRequired2Fa ? (
                  <div className="title-form">
                    <h1> {t('headline2FA')} </h1>
                  </div>
                ) : (
                  <div className="title-form">
                    <h1>
                      {' '}
                      {t('theCode')} <span>{coupon_status?.code}</span> {t(`${coupon_status?.status}_coupon`)}
                      <a
                        href={`mailto:kontakt@wilderness-international.org?subject=${t('mailSubject')}`}
                        className={`${coupon_status?.status === STATUS_COUPON.BLOCKED ? 'display-mail' : 'hide-mail'}`}
                      >
                        {' '}
                        {t('blocked_coupon_email')}.{' '}
                      </a>{' '}
                    </h1>
                  </div>
                )}

                <div className="action-form">
                  <p> {isRequired2Fa ? description2FA[languageDefault.code.toLowerCase()] : t('tryCouponCode')} </p>
                  <div className="input-form">
                    <div className="input-container">
                      <InputText
                        className={`${isCode ? 'error-input' : ''}`}
                        id="username"
                        value={code}
                        onChange={e => onHandleCouponCode(e)}
                        // placeholder={isRequired2Fa ? "" : "WIN-AB2C34"}
                      />
                      {isCode && isRequired2Fa ? <p className="error-message">{t('error2FA')}</p> : ''}
                    </div>
                    <div className="apply-btn">
                      <Button
                        loading={isLoading}
                        label={t('apply')}
                        className="p-button-rounded"
                        icon="fa-solid fa-chevron-right"
                        iconPos="right"
                        onClick={submitCoupon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCouponComponent;
