import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import MessageCouponComponent from '../components/message-coupon/message-coupon';
import ConverterParameters from '../components/converter/models/ConverterParameters';
import { useEffect, useState } from 'react';
import { getLanguageDefault } from '../utils/Utils';
import { withTranslation } from 'react-i18next';
import LoadingComponent from '../common/Loading';
import { fetchCouponsData } from '../utils/funtionCommon';

const CouponMessage = ({ location, t, i18n }) => {
  const parameters = new ConverterParameters(location.search);
  const coupon = parameters.getCouponCode();
  const [couponDetail, setCouponDetail] = useState({
    detail: null,
    area: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getCouponDetail = async coupon => {
    await fetchCouponsData(coupon, setCouponDetail, false, true);
    setIsLoading(false);
  };

  useEffect(() => {
    i18n.changeLanguage(getLanguageDefault().code.toLowerCase());
    getCouponDetail(coupon);
  }, []);

  return (
    <Layout isShowAction={false} minHeight="100%">
      <Seo title="Message Code" />
      <div className="Message-coupon-page">
        {couponDetail && couponDetail.detail ? (
          <MessageCouponComponent
            coupon={coupon}
            couponDetail={couponDetail}
            t={t}
            i18n={i18n}
            languageDefault={getLanguageDefault()}
            getCouponDetail={getCouponDetail}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ) : (
          <LoadingComponent />
        )}
      </div>
    </Layout>
  );
};

export default withTranslation('blog')(CouponMessage);
